var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-form',{on:{"submit":function($event){$event.preventDefault();return _vm.submitResponse.apply(null, arguments)}}},[_c('p',{staticClass:"mb-3"},[_vm._v(" Enter appropriate identifying information for each chemical used during Fundamental Skills. ")]),_c('table',[_c('thead',[_c('tr',_vm._l((_vm.headings),function(head){return _c('th',{key:head.text},[_vm._v(" "+_vm._s(head.text)+" ")])}),0)]),_c('tbody',[_c('tr',[_c('td',[_vm._v("Deionized Water")]),_c('td',{staticStyle:{"width":"150px"}},[_c('chemical-notation-input',{staticClass:"mb-5",attrs:{"disabled":!_vm.allowEditing},model:{value:(_vm.inputs.chemFormulaH2O),callback:function ($$v) {_vm.$set(_vm.inputs, "chemFormulaH2O", $$v)},expression:"inputs.chemFormulaH2O"}})],1),_c('td',{staticClass:"py-2 px-2 mx-0 centered-input"},[_c('calculation-input',{staticClass:"centered-input",attrs:{"disabled":!_vm.allowEditing},model:{value:(_vm.inputs.mmH2O),callback:function ($$v) {_vm.$set(_vm.inputs, "mmH2O", $$v)},expression:"inputs.mmH2O"}})],1),_c('td',[_c('v-select',{attrs:{"items":_vm.stateOptions,"outlined":"","hide-details":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item}})]}}]),model:{value:(_vm.inputs.physStateH2O),callback:function ($$v) {_vm.$set(_vm.inputs, "physStateH2O", $$v)},expression:"inputs.physStateH2O"}})],1),_c('td',[_vm._v("N/A")]),_c('td',[_vm._v("N/A")]),_c('td',{staticClass:"py-2 px-2 mx-0 centered-input"},[_c('calculation-input',{staticClass:"centered-input",attrs:{"disabled":!_vm.allowEditing},model:{value:(_vm.inputs.vH2O),callback:function ($$v) {_vm.$set(_vm.inputs, "vH2O", $$v)},expression:"inputs.vH2O"}})],1),_c('td',[_vm._v("N/A")]),_c('td',{staticClass:"py-2 px-2 mx-0 centered-input"},[_c('calculation-input',{staticClass:"centered-input",attrs:{"disabled":!_vm.allowEditing},model:{value:(_vm.inputs.dH2O),callback:function ($$v) {_vm.$set(_vm.inputs, "dH2O", $$v)},expression:"inputs.dH2O"}})],1)]),_c('tr',[_c('td',[_vm._v("Sucrose")]),_c('td',{staticStyle:{"width":"100px"}},[_c('chemical-notation-input',{staticClass:"mb-5",attrs:{"disabled":!_vm.allowEditing},model:{value:(_vm.inputs.chemFormulaSuc),callback:function ($$v) {_vm.$set(_vm.inputs, "chemFormulaSuc", $$v)},expression:"inputs.chemFormulaSuc"}})],1),_c('td',{staticClass:"py-2 px-2 mx-0 centered-input"},[_c('calculation-input',{staticClass:"centered-input",attrs:{"disabled":!_vm.allowEditing},model:{value:(_vm.inputs.mmSuc),callback:function ($$v) {_vm.$set(_vm.inputs, "mmSuc", $$v)},expression:"inputs.mmSuc"}})],1),_c('td',[_c('v-select',{attrs:{"items":_vm.stateOptions,"outlined":"","hide-details":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item}})]}}]),model:{value:(_vm.inputs.physStateSuc),callback:function ($$v) {_vm.$set(_vm.inputs, "physStateSuc", $$v)},expression:"inputs.physStateSuc"}})],1),_c('td',{staticClass:"py-2 px-2 mx-0 centered-input"},[_c('calculation-input',{staticClass:"centered-input",attrs:{"disabled":!_vm.allowEditing},model:{value:(_vm.inputs.mSuc),callback:function ($$v) {_vm.$set(_vm.inputs, "mSuc", $$v)},expression:"inputs.mSuc"}})],1),_c('td',{staticClass:"py-2 px-2 mx-0 centered-input"},[_c('calculation-input',{staticClass:"centered-input",attrs:{"disabled":!_vm.allowEditing},model:{value:(_vm.inputs.molSuc),callback:function ($$v) {_vm.$set(_vm.inputs, "molSuc", $$v)},expression:"inputs.molSuc"}})],1),_c('td',[_vm._v("N/A")]),_c('td',[_vm._v("N/A")]),_c('td',[_vm._v("N/A")])]),_c('tr',[_c('td',[_vm._v("Sucrose solution #1 (most dilute)")]),_c('td',[_vm._v("N/A")]),_c('td',[_vm._v("N/A")]),_c('td',[_c('v-select',{attrs:{"items":_vm.stateOptions,"outlined":"","hide-details":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item}})]}}]),model:{value:(_vm.inputs.physStateSuc1),callback:function ($$v) {_vm.$set(_vm.inputs, "physStateSuc1", $$v)},expression:"inputs.physStateSuc1"}})],1),_c('td',{staticClass:"py-2 px-2 mx-0 centered-input"},[_c('calculation-input',{staticClass:"centered-input",attrs:{"disabled":!_vm.allowEditing},model:{value:(_vm.inputs.mSuc1),callback:function ($$v) {_vm.$set(_vm.inputs, "mSuc1", $$v)},expression:"inputs.mSuc1"}})],1),_c('td',{staticClass:"py-2 px-2 mx-0 centered-input"},[_c('calculation-input',{staticClass:"centered-input",attrs:{"disabled":!_vm.allowEditing},model:{value:(_vm.inputs.molSuc1),callback:function ($$v) {_vm.$set(_vm.inputs, "molSuc1", $$v)},expression:"inputs.molSuc1"}})],1),_c('td',{staticClass:"py-2 px-2 mx-0 centered-input"},[_c('calculation-input',{staticClass:"centered-input",attrs:{"disabled":!_vm.allowEditing},model:{value:(_vm.inputs.vSuc1),callback:function ($$v) {_vm.$set(_vm.inputs, "vSuc1", $$v)},expression:"inputs.vSuc1"}})],1),_c('td',{staticClass:"py-2 px-2 mx-0 centered-input"},[_c('calculation-input',{staticClass:"centered-input",attrs:{"disabled":!_vm.allowEditing},model:{value:(_vm.inputs.cSuc1),callback:function ($$v) {_vm.$set(_vm.inputs, "cSuc1", $$v)},expression:"inputs.cSuc1"}})],1),_c('td',[_vm._v("N/A")])]),_c('tr',[_c('td',[_vm._v("Sucrose solution #2")]),_c('td',[_vm._v("N/A")]),_c('td',[_vm._v("N/A")]),_c('td',[_c('v-select',{attrs:{"items":_vm.stateOptions,"outlined":"","hide-details":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item}})]}}]),model:{value:(_vm.inputs.physStateSuc2),callback:function ($$v) {_vm.$set(_vm.inputs, "physStateSuc2", $$v)},expression:"inputs.physStateSuc2"}})],1),_c('td',{staticClass:"py-2 px-2 mx-0 centered-input"},[_c('calculation-input',{staticClass:"centered-input",attrs:{"disabled":!_vm.allowEditing},model:{value:(_vm.inputs.mSuc2),callback:function ($$v) {_vm.$set(_vm.inputs, "mSuc2", $$v)},expression:"inputs.mSuc2"}})],1),_c('td',{staticClass:"py-2 px-2 mx-0 centered-input"},[_c('calculation-input',{staticClass:"centered-input",attrs:{"disabled":!_vm.allowEditing},model:{value:(_vm.inputs.molSuc2),callback:function ($$v) {_vm.$set(_vm.inputs, "molSuc2", $$v)},expression:"inputs.molSuc2"}})],1),_c('td',{staticClass:"py-2 px-2 mx-0 centered-input"},[_c('calculation-input',{staticClass:"centered-input",attrs:{"disabled":!_vm.allowEditing},model:{value:(_vm.inputs.vSuc2),callback:function ($$v) {_vm.$set(_vm.inputs, "vSuc2", $$v)},expression:"inputs.vSuc2"}})],1),_c('td',{staticClass:"py-2 px-2 mx-0 centered-input"},[_c('calculation-input',{staticClass:"centered-input",attrs:{"disabled":!_vm.allowEditing},model:{value:(_vm.inputs.cSuc2),callback:function ($$v) {_vm.$set(_vm.inputs, "cSuc2", $$v)},expression:"inputs.cSuc2"}})],1),_c('td',[_vm._v("N/A")])]),_c('tr',[_c('td',[_vm._v("Sucrose solution #3")]),_c('td',[_vm._v("N/A")]),_c('td',[_vm._v("N/A")]),_c('td',[_c('v-select',{attrs:{"items":_vm.stateOptions,"outlined":"","hide-details":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item}})]}}]),model:{value:(_vm.inputs.physStateSuc3),callback:function ($$v) {_vm.$set(_vm.inputs, "physStateSuc3", $$v)},expression:"inputs.physStateSuc3"}})],1),_c('td',{staticClass:"py-2 px-2 mx-0 centered-input"},[_c('calculation-input',{staticClass:"centered-input",attrs:{"disabled":!_vm.allowEditing},model:{value:(_vm.inputs.mSuc3),callback:function ($$v) {_vm.$set(_vm.inputs, "mSuc3", $$v)},expression:"inputs.mSuc3"}})],1),_c('td',{staticClass:"py-2 px-2 mx-0 centered-input"},[_c('calculation-input',{staticClass:"centered-input",attrs:{"disabled":!_vm.allowEditing},model:{value:(_vm.inputs.molSuc3),callback:function ($$v) {_vm.$set(_vm.inputs, "molSuc3", $$v)},expression:"inputs.molSuc3"}})],1),_c('td',{staticClass:"py-2 px-2 mx-0 centered-input"},[_c('calculation-input',{staticClass:"centered-input",attrs:{"disabled":!_vm.allowEditing},model:{value:(_vm.inputs.vSuc3),callback:function ($$v) {_vm.$set(_vm.inputs, "vSuc3", $$v)},expression:"inputs.vSuc3"}})],1),_c('td',{staticClass:"py-2 px-2 mx-0 centered-input"},[_c('calculation-input',{staticClass:"centered-input",attrs:{"disabled":!_vm.allowEditing},model:{value:(_vm.inputs.cSuc3),callback:function ($$v) {_vm.$set(_vm.inputs, "cSuc3", $$v)},expression:"inputs.cSuc3"}})],1),_c('td',[_vm._v("N/A")])]),_c('tr',[_c('td',[_vm._v("Sucrose solution #4 (most concentrated)")]),_c('td',[_vm._v("N/A")]),_c('td',[_vm._v("N/A")]),_c('td',[_c('v-select',{attrs:{"items":_vm.stateOptions,"outlined":"","hide-details":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item}})]}}]),model:{value:(_vm.inputs.physStateSuc4),callback:function ($$v) {_vm.$set(_vm.inputs, "physStateSuc4", $$v)},expression:"inputs.physStateSuc4"}})],1),_c('td',{staticClass:"py-2 px-2 mx-0 centered-input"},[_c('calculation-input',{staticClass:"centered-input",attrs:{"disabled":!_vm.allowEditing},model:{value:(_vm.inputs.mSuc4),callback:function ($$v) {_vm.$set(_vm.inputs, "mSuc4", $$v)},expression:"inputs.mSuc4"}})],1),_c('td',{staticClass:"py-2 px-2 mx-0 centered-input"},[_c('calculation-input',{staticClass:"centered-input",attrs:{"disabled":!_vm.allowEditing},model:{value:(_vm.inputs.molSuc4),callback:function ($$v) {_vm.$set(_vm.inputs, "molSuc4", $$v)},expression:"inputs.molSuc4"}})],1),_c('td',{staticClass:"py-2 px-2 mx-0 centered-input"},[_c('calculation-input',{staticClass:"centered-input",attrs:{"disabled":!_vm.allowEditing},model:{value:(_vm.inputs.vSuc4),callback:function ($$v) {_vm.$set(_vm.inputs, "vSuc4", $$v)},expression:"inputs.vSuc4"}})],1),_c('td',{staticClass:"py-2 px-2 mx-0 centered-input"},[_c('calculation-input',{staticClass:"centered-input",attrs:{"disabled":!_vm.allowEditing},model:{value:(_vm.inputs.cSuc4),callback:function ($$v) {_vm.$set(_vm.inputs, "cSuc4", $$v)},expression:"inputs.cSuc4"}})],1),_c('td',[_vm._v("N/A")])])])]),_c('p',{staticClass:"mt-5"},[_vm._v("Select all the glassware/equipment/instrumentation used in this lab.")]),_vm._l((_vm.equipmentOptions),function(option){return _c('p',{key:option.value,staticClass:"mb-0 mt-0"},[_c('v-checkbox',{staticClass:"ml-6 mb-n4 mt-0",attrs:{"value":option.value},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('stemble-latex',{attrs:{"content":option.value}})]},proxy:true}],null,true),model:{value:(_vm.inputs.equipment),callback:function ($$v) {_vm.$set(_vm.inputs, "equipment", $$v)},expression:"inputs.equipment"}})],1)})],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }