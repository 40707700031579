<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">
        Enter appropriate identifying information for each chemical used during Fundamental Skills.
      </p>

      <table>
        <thead>
          <tr>
            <th v-for="head in headings" :key="head.text">
              {{ head.text }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Deionized Water</td>
            <td style="width: 150px">
              <chemical-notation-input
                v-model="inputs.chemFormulaH2O"
                class="mb-5"
                :disabled="!allowEditing"
              />
            </td>
            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.mmH2O"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>
            <td>
              <v-select v-model="inputs.physStateH2O" :items="stateOptions" outlined hide-details>
                <template #item="{item}">
                  <stemble-latex class="no-text-transform" :content="item" />
                </template>
                <template #selection="{item}">
                  <stemble-latex class="no-text-transform" :content="item" />
                </template>
              </v-select>
            </td>
            <td>N/A</td>
            <td>N/A</td>
            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.vH2O"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>
            <td>N/A</td>
            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.dH2O"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>
          </tr>
          <tr>
            <td>Sucrose</td>
            <td style="width: 100px">
              <chemical-notation-input
                v-model="inputs.chemFormulaSuc"
                class="mb-5"
                :disabled="!allowEditing"
              />
            </td>
            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.mmSuc"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>
            <td>
              <v-select v-model="inputs.physStateSuc" :items="stateOptions" outlined hide-details>
                <template #item="{item}">
                  <stemble-latex class="no-text-transform" :content="item" />
                </template>
                <template #selection="{item}">
                  <stemble-latex class="no-text-transform" :content="item" />
                </template>
              </v-select>
            </td>
            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.mSuc"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>
            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.molSuc"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>
            <td>N/A</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Sucrose solution #1 (most dilute)</td>
            <td>N/A</td>
            <td>N/A</td>
            <td>
              <v-select v-model="inputs.physStateSuc1" :items="stateOptions" outlined hide-details>
                <template #item="{item}">
                  <stemble-latex class="no-text-transform" :content="item" />
                </template>
                <template #selection="{item}">
                  <stemble-latex class="no-text-transform" :content="item" />
                </template>
              </v-select>
            </td>
            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.mSuc1"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>
            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.molSuc1"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>
            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.vSuc1"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>
            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.cSuc1"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Sucrose solution #2</td>
            <td>N/A</td>
            <td>N/A</td>
            <td>
              <v-select v-model="inputs.physStateSuc2" :items="stateOptions" outlined hide-details>
                <template #item="{item}">
                  <stemble-latex class="no-text-transform" :content="item" />
                </template>
                <template #selection="{item}">
                  <stemble-latex class="no-text-transform" :content="item" />
                </template>
              </v-select>
            </td>
            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.mSuc2"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>
            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.molSuc2"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>
            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.vSuc2"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>
            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.cSuc2"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Sucrose solution #3</td>
            <td>N/A</td>
            <td>N/A</td>
            <td>
              <v-select v-model="inputs.physStateSuc3" :items="stateOptions" outlined hide-details>
                <template #item="{item}">
                  <stemble-latex class="no-text-transform" :content="item" />
                </template>
                <template #selection="{item}">
                  <stemble-latex class="no-text-transform" :content="item" />
                </template>
              </v-select>
            </td>
            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.mSuc3"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>
            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.molSuc3"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>
            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.vSuc3"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>
            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.cSuc3"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Sucrose solution #4 (most concentrated)</td>
            <td>N/A</td>
            <td>N/A</td>
            <td>
              <v-select v-model="inputs.physStateSuc4" :items="stateOptions" outlined hide-details>
                <template #item="{item}">
                  <stemble-latex class="no-text-transform" :content="item" />
                </template>
                <template #selection="{item}">
                  <stemble-latex class="no-text-transform" :content="item" />
                </template>
              </v-select>
            </td>
            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.mSuc4"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>
            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.molSuc4"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>
            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.vSuc4"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>
            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.cSuc4"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>
            <td>N/A</td>
          </tr>
        </tbody>
      </table>
      <p class="mt-5">Select all the glassware/equipment/instrumentation used in this lab.</p>
      <p v-for="option in equipmentOptions" :key="option.value" class="mb-0 mt-0">
        <v-checkbox v-model="inputs.equipment" :value="option.value" class="ml-6 mb-n4 mt-0">
          <template v-slot:label>
            <stemble-latex :content="option.value" />
          </template>
        </v-checkbox>
      </p>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex.vue';
import ChemicalLatex from '@/tasks/components/displayers/ChemicalLatex.vue';
import ChemicalNotationInput from '@/chemical-equations/components/ChemicalNotationInput.vue';
import CalculationInput from '@/tasks/components/inputs/CalculationInput.vue';

export default {
  name: 'ChemUCI1LCFS1IL2',
  components: {
    CalculationInput,
    ChemicalNotationInput,
    ChemicalLatex,
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        chemFormulaH2O: null,
        mmH2O: null,
        physStateH2O: null,
        vH2O: null,
        dH2O: null,
        chemFormulaSuc: null,
        mmSuc: null,
        physStateSuc: null,
        mSuc: null,
        molSuc: null,
        physStateSuc1: null,
        mSuc1: null,
        molSuc1: null,
        vSuc1: null,
        cSuc1: null,
        physStateSuc2: null,
        mSuc2: null,
        molSuc2: null,
        vSuc2: null,
        cSuc2: null,
        physStateSuc3: null,
        mSuc3: null,
        molSuc3: null,
        vSuc3: null,
        cSuc3: null,
        physStateSuc4: null,
        mSuc4: null,
        molSuc4: null,
        vSuc4: null,
        cSuc4: null,
        equipment: [],
      },
      headings: [
        {text: 'Chemical Name'},
        {text: 'Formula'},
        {text: 'Molar Mass (g/mol)'},
        {text: 'Physical States'},
        {text: 'Total Mass Used (g)'},
        {text: 'Moles'},
        {text: 'Total Volume Used (mL)'},
        {text: 'Concentration (M)'},
        {text: 'density (g/mL)'},
      ],
      stateOptions: ['l', 's', 'g', 'aq'],
      equipmentOptions: [
        {value: 'beaker(s)'},
        {value: 'Buchner funnel'},
        {value: 'buret'},
        {value: 'capillary/melting tube'},
        {value: 'clamp and stand'},
        {value: 'cuvette'},
        {value: 'Digi-Melt (melting point apparatus)'},
        {value: 'digital balance'},
        {value: 'disposable pipet'},
        {value: 'drying oven'},
        {value: 'Erlenmeyer flask'},
        {value: 'filter paper'},
        {value: 'funnel'},
        {value: 'graduated cylinder'},
        {value: 'ice'},
        {value: 'KimWipes (tissues)'},
        {value: 'LabQuest'},
        {value: 'pencil'},
        {value: 'pipet suction bulb'},
        {value: 'probe (pH)'},
        {value: 'probe (temperature)'},
        {value: 'scintillation vial'},
        {value: 'side arm flask'},
        {value: 'spatula or scoopula'},
        {value: 'spectrometer'},
        {value: 'spotter'},
        {value: 'stir bar'},
        {value: 'stirrer/hotplate'},
        {value: 'test tube'},
        {value: 'thermometer (alcohol)'},
        {value: 'TLC (developing) chamber'},
        {value: 'TLC plate'},
        {value: 'TLC spotter'},
        {value: 'UV lamp'},
        {value: 'volumetric flask'},
        {value: 'volumetric pipet'},
        {value: 'watch glass'},
        {value: 'weighing boat or paper'},
      ],
    };
  },
};
</script>
<style scoped>
table {
  border-collapse: collapse;
}

td,
th {
  border-style: solid;
  border-width: 1px;
  padding: 10px;
}
</style>
